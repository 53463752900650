import createClient, { type Middleware } from 'openapi-fetch'
import type { paths } from '~/src/api/schema'
import { useSignOut } from '~/composables/useSignOut'

interface IError {
  '@context': string
  '@type': string
  'hydra:description': string
  'hydra:title': string
}

export function useApi () {
  const config = useRuntimeConfig()
  const client = useSupabaseClient()
  const { signOut } = useSignOut()
  const cookie = useCookie('sb-access-token')

  client.auth.onAuthStateChange((event, session) => {
    if (event === 'TOKEN_REFRESHED') {
      cookie.value = session?.access_token
    }
  })

  const apiClient = ref(
    createClient<paths>(
      {
        headers: cookie.value ? {
          Authorization: `Bearer ${cookie.value}`,
          'Content-Type': 'application/ld+json'
        } : {},
        baseUrl: config.public.apiEndpoint
      })
  )

  const throwOnError: Middleware = {
    async onResponse ({ response }) {
      if (response.status >= 400) {
        const body: IError = response.headers.get('content-type')?.includes('json')
          ? await response.clone().json()
          : await response.clone().text()
        throw new Error(body['hydra:description'])
      }
      return undefined
    }
  }

  const logoutOnUnauthorized: Middleware = {
    async onResponse ({ response }) {
      if (response.status === 401) {
        await signOut()
      }
      return undefined
    }
  }

  const authorizationHeader: Middleware = {
    async onRequest({ request }) {
      request.headers.set('Authorization', `Bearer ${cookie.value}`);
      return request;
    }
  }

  apiClient.value.use(authorizationHeader)
  apiClient.value.use(throwOnError)
  apiClient.value.use(logoutOnUnauthorized)

  return apiClient.value
}
